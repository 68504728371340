@use '@angular/material' as mat;

@mixin station-preview-component-theme($theme) {
  .station-pin-overview {
    padding-top: 100px;
    bottom: 12px !important;

    @media (max-width: 768px) {
      padding-top: 24px;
    }

    .leaflet-popup-content {
      margin: 16px !important;
    }
  }

  .station-preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 370px;

    .station-preview-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      position: relative;
      padding-right: 62px;

      button {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        color: mat.get-theme-color($theme, "secondary", 60);
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        flex-shrink: 0;

        .text {
          font-size: 12px;
          font-weight: 500;
        }

        .mat-mdc-button-persistent-ripple {
          border-radius: inherit;
        }

        svg {
          width: 27px;
          height: 37px;
        }
      }
    }

    .station-name {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .location-type {
      font-size: 16px;
      font-weight: 400;
      color: mat.get-theme-color($theme, "neutral", 40);
      margin-bottom: 8px;
    }

    p {
      margin: 0px; // leaflet styling
    }

    .pill-container {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .pill {
        display: inline-flex;
        flex: 0;
        border-radius: 16px;
        border-width: 1px;
        padding: 3px 10px;
        font-size: 14px;
      }

      .pill-green {
        border-color: rgb(221, 255, 220);
        background-color: rgb(221, 255, 220);
        color: rgb(4, 162, 0)
      }

      .pill-red {
        border-color: rgb(254, 242, 242);
        background-color: rgb(254, 242, 242);
        color: rgb(248, 113, 113)
      }

      .pill-blue {
        border-color: rgb(228, 244, 255);
        background-color: rgb(228, 244, 255);
        color: rgb(0, 146, 243)
      }

      .pill-ash {
        border-color: mat.get-theme-color($theme, 'neutral', 95);
        background-color: mat.get-theme-color($theme, 'neutral', 95);
        color: mat.get-theme-color($theme, 'neutral', 30);
      }
    }

    .station-rating {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
      margin-bottom: 12px;

      span {
        color: mat.get-theme-color($theme, 'neutral', 10);
        font-size: 14px;
        display: inline-block;
      }

      .icons {
        display: flex;
        align-items: center;

        mat-icon {
          width: auto;
          height: auto;
          font-size: 20px;
          color: mat.get-theme-color($theme, 'neutral', 80);
          font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24;

          &.active {
            color: #fcd34d;
          }
        }
      }
    }

    .app-connector-list {
      &>p {
        margin-bottom: 12px;
        font-size: 14px;
        color: mat.get-theme-color($theme, "neutral", 40);
      }
    }

    .info-list {
      margin-top: 0;
      margin-bottom: 15px;
      padding: 0;
      list-style: none;
      display: grid;
      gap: 4px;

      li {
        display: flex;
        align-items: start;
        gap: 8px;
        font-size: 14px;
        color: mat.get-theme-color($theme, "neutral", 40);

        mat-icon {
          flex-shrink: 0;
          font-size: 20px;
          width: 20px;
          height: 20px;
          color: mat.get-theme-color($theme, "primary", 60);
        }
      }
    }
  }
}
