@use '@angular/material' as mat;

@mixin legal-component-theme($theme) {
  .app-legal-header {
    background-color: mat.get-theme-color($theme, 'primary', 99);
    background-image: linear-gradient(to right, mat.get-theme-color($theme, 'primary', 99) 0%, mat.get-theme-color($theme, 'secondary', 98) 100%);
    padding: 10px 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      position: relative;

      .logo {
        flex-shrink: 0;
      }

      .links {
        display: flex;
        flex-shrink: 1;
        width: calc(100% - 185px);
      }

      .navbar {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 28px;
        flex-wrap: wrap;
        width: 100%;
        flex-shrink: 1;

        a {
          font-size: 15px;
          color: mat.get-theme-color($theme, 'neutral', 40);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            color: mat.get-theme-color($theme, 'neutral', 20);
          }
        }
      }

      .right-buttons {
        flex-shrink: 0;
        width: fit-content;
        margin-left: auto;

        @media (max-width: 959px) {
          margin-left: 0;
        }

        [mat-flat-button] {
          background-color: mat.get-theme-color($theme, 'primary', 60);
        }
      }

      .navbar-toggle {
        @media (min-width: 960px) {
          display: none;
        }
      }

      @media (max-width: 959px) {
        .links {
          position: absolute;
          top: 100%;
          width: 100%;
          left: 0;
          background-color: mat.get-theme-color($theme, 'primary', 100);
          flex-direction: column;
          padding: 10px;
          border-top: 1px solid mat.get-theme-color($theme, 'neutral', 90);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          z-index: 999;

          .navbar {
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
            margin-bottom: 16px;

            a {
              border-radius: 4px;
              display: block;
              padding: 8px 16px;
              width: 100%;

              &:hover,
              &.active {
                background-color: mat.get-theme-color($theme, 'neutral', 95);
                color: mat.get-theme-color($theme, 'neutral', 10);
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .app-legal-footer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-top: 80px;
    padding: 0 16px;

    .footer-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 35px;

      img {
        width: 116px;
      }

      .links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        a {
          text-decoration: none;
          color: mat.get-theme-color($theme, 'neutral', 40);
          font-size: 15px;
          font-weight: 500;
        }

        mat-divider {
          height: 16px;
        }
      }
    }

    .footer-download {
      text-align: center;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: mat.get-theme-color($theme, 'neutral', 90);
      padding: 30px 0;

      h2 {
        margin: 0;
        margin-bottom: 16px;
      }
    }

    .footer-bottom {
      padding-top: 35px;
      padding-bottom: 75px;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 650px) {
        justify-content: center;
        flex-direction: column;
      }

      .copyright {
        p {
          color: mat.get-theme-color($theme, 'neutral', 40);
          font-size: 18px;
        }
      }

      .footer-links {
        display: flex;
        flex-direction: row;
        gap: 35px;

        img {
          width: 24px;
        }
      }
    }
  }
}
