@use '@angular/material' as mat;

@mixin add-wallet-dialog-component-theme($theme) {
  .app-add-wallet-dialog {
    background-color: mat.get-theme-color($theme, 'neutral', 100);
    height: 100%;

    .header {
      text-align: center;
      position: relative;
      padding: 18px 32px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      .close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
      }
    }

    .content {
      padding: 10px 32px;

      form {
        display: grid;
        gap: 18px;
        width: 100%;
        padding: 16px 0;
        margin: 0;

        mat-form-field {
          margin-bottom: -20px;

          @include mat.form-field-density(-2);
        }

        button {
          margin-top: 16px;
        }
      }
    }

    #card-element {
      padding: 0 16px;
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid mat.get-theme-color($theme, 'neutral', 50);
      border-radius: 4px;
      font-size: 16px;
    }
  }
}
