@use '@angular/material' as mat;

@mixin user-dropdown-component-theme($theme) {
  .app-user-dropdown {
    margin-left: auto;

    .auth-button {
      background-color: mat.get-theme-color($theme, 'secondary', 80);
      color: mat.get-theme-color($theme, 'secondary', 20);
    }
  }

  .user-dropdown-menu {
    margin: 12px 0;
    padding: 0 8px;
    border-radius: 12px;
    max-width: unset;
    width: 280px;
    background-color: mat.get-theme-color($theme, 'neutral', 100);

    [mat-menu-item] {
      border-radius: 8px;

      &.verify-button {
        background-color: #faeaea;
        color: #d32f2f;

        mat-icon {
          color: currentColor;
        }
      }
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px 0;

      .author {
        display: grid;
        gap: 2px;
        flex-shrink: 1;

        .user-name {
          font-size: 16px;
          font-weight: 500;
          color: mat.get-theme-color($theme, 'neutral', 20);
          margin: 0;
        }

        a {
          font-size: 14px;
          font-weight: 400;
          color: mat.get-theme-color($theme, 'secondary', 50);
          text-decoration: none;
        }
      }
    }
  }

  .user-dropdown-button {
    padding: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .user-dropdown-menu,
  .user-dropdown-button {
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: mat.get-theme-color($theme, 'neutral', 90);
      color: mat.get-theme-color($theme, 'neutral', 40);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 20px;

      &.warning {
        background-color: #faeaea;
        color: #d32f2f;
        border: 1px solid #d32f2f;
      }

      mat-icon {
        margin: 0;
        color: currentColor;
      }

      img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
      }
    }
  }
}
