@use '@angular/material' as mat;

@mixin ratings-tab-component-theme($theme) {
  .app-reviews-tab {
    .reviews-bar {
      margin-bottom: 30px;

      h3 {
        font-size: 18px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: 600;
        color: mat.get-theme-color($theme, 'neutral', 20);
      }

      .progress {
        display: grid;
        gap: 18px;

        p {
          margin: 0;
          margin-bottom: 4px;
          color: mat.get-theme-color($theme, 'neutral', 30);
        }

        mat-progress-bar {
          --mdc-linear-progress-track-height: 12px;
          --mdc-linear-progress-active-indicator-height: 12px;
          border-radius: 50px;
        }
      }
    }

    .filters {
      margin-bottom: 32px;

      .filter-button {
        background-color: mat.get-theme-color($theme, 'secondary', 90);
        color: mat.get-theme-color($theme, 'secondary', 0);
      }
    }

    .reviews {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
