@use '@angular/material' as mat;

@mixin station-image-component-theme($theme) {
  .app-station-image {
    background-color: mat.get-theme-color($theme, 'neutral', 100);
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 520px;
    max-width: 520px;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    border-left: 1px solid mat.get-theme-color($theme, 'neutral', 90);

    @media (max-width: 1095px) {
      left: 0;
      max-width: 520px;
      box-shadow: none;
      border-left: none;
    }

    @media (max-width: 600px) {
      left: 0;
      top: 0;
      max-width: 100%;
      height: 100%;
      box-shadow: none;
    }

    mat-toolbar {
      background-color: mat.get-theme-color($theme, 'neutral', 100);

      .example-spacer {
        flex: 1 1 auto;
      }
    }

    .station-content {
      padding: 26px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      .station-image {
        height: 700px;
        position: relative;
      }

      .station-author {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        margin-top: auto;

        .author {
          display: flex;
          align-items: center;
          gap: 12px;
          text-decoration: none;

          .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: mat.get-theme-color($theme, 'neutral', 90);
            color: mat.get-theme-color($theme, 'neutral', 40);
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-size: 20px;
          }

          .content {
            display: grid;
            gap: 1px;

            .name {
              font-size: 18px;
              font-weight: 400;
              color: mat.get-theme-color($theme, 'neutral', 20);
            }

            .vehicle {
              font-size: 16px;
              font-weight: 400;
              color: mat.get-theme-color($theme, 'neutral', 40);
            }
          }
        }

        .date {
          flex-shrink: 0;
          font-size: 14px;
          font-weight: 400;
          color: mat.get-theme-color($theme, 'neutral', 40);
        }
      }
    }
  }
}
