@use '@angular/material' as mat;

@mixin review-item-component-theme($theme) {
  .app-review-item {
    padding-bottom: 20px;
    border-bottom: 1px solid mat.get-theme-color($theme, 'neutral', 90);

    .header {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      >a {
        display: flex;
        align-items: center;
        gap: 12px;
        text-decoration: none;
      }

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: mat.get-theme-color($theme, 'neutral', 90);
        color: mat.get-theme-color($theme, 'neutral', 40);
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 20px;
      }

      .author {
        display: grid;
        gap: 1px;

        .name {
          font-size: 18px;
          font-weight: 400;
          color: mat.get-theme-color($theme, 'neutral', 20);
        }

        .vehicle {
          font-size: 16px;
          font-weight: 400;
          color: mat.get-theme-color($theme, 'neutral', 40);
        }
      }

      .delete-button {
        position: absolute;
        right: 0;
        top: -10px;
      }
    }

    .rating-icons {
      display: flex;
      align-items: center;
      margin: 16px 0;

      mat-icon {
        width: auto;
        height: auto;
        font-size: 20px;
        color: mat.get-theme-color($theme, 'neutral', 80);
        font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24;

        &.active {
          color: #fcd34d;
        }
      }
    }

    .title {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: mat.get-theme-color($theme, 'neutral', 20);
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      color: mat.get-theme-color($theme, 'neutral', 40);
      margin-top: 8px;
      margin-bottom: 0;
      white-space: pre-wrap;
    }

    .toggle-button {
      text-decoration: underline;
      color: mat.get-theme-color($theme, 'secondary', 50);
      cursor: pointer;
      padding: 0;
      outline: none;
      border: none;
      background: none;
      font-weight: 500;
      font-size: 14px;
      margin-top: 6px;
    }

    .people-found-helpful,
    .date {
      font-size: 14px;
      font-weight: 500;
      color: mat.get-theme-color($theme, 'neutral', 50);
      margin-bottom: 0;
    }

    .people-found-helpful {
      margin-top: 20px;
    }

    .helpful-button {
      margin-top: 12px;
      cursor: pointer;
      padding: 0;
      outline: none;
      border: none;
      background: transparent;
      font-weight: 500;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      color: mat.get-theme-color($theme, 'neutral', 50);

      mat-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        color: mat.get-theme-color($theme, 'primary', 50);
        transition: color 0.2s ease-in-out;
      }

      &.liked {
        font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24;
      }
    }

    .date {
      margin-top: 12px;
    }
  }
}
