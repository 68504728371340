@use '@angular/material' as mat;

@mixin personal-information-component-theme($theme) {
  .app-personal-information {
    .profile-photo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      padding: 16px;
      background-color: mat.get-theme-color($theme, 'neutral', 90);
      border-radius: 12px;

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: mat.get-theme-color($theme, 'neutral', 100);
        color: mat.get-theme-color($theme, 'neutral', 40);
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        mat-icon {
          font-size: 20px;
          width: 20px;
          height: 20px;
        }

        app-gc-image {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: aboslute;
            inset: 0;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      button:not(:disabled) {
        background-color: mat.get-theme-color($theme, 'secondary', 60);
      }
    }

    form {
      display: grid;
      gap: 12px;
      max-width: 500px;
      width: 100%;

      mat-form-field {
        margin-bottom: -20px;

        @include mat.form-field-density(-2);
      }

      .buttons {
        margin-top: 16px;

        button .mdc-button__label {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }
    }
  }
}
