@use '@angular/material' as mat;

@mixin report-dialog-component-theme($theme) {
  .app-report-dialog {
    background-color: mat.get-theme-color($theme, 'neutral', 100);
    height: 100%;

    .header {
      position: relative;
      padding: 18px 32px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      .close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
      }
    }

    .content {
      padding: 20px 32px;

      .description {
        color: mat.get-theme-color($theme, 'neutral', 40);
        font-size: 16px;
        margin: 0;
        margin-bottom: 20px;
      }

      form {
        display: grid;
        gap: 20px;

        mat-form-field {
          width: 100%;
          margin-bottom: -20px;

          @include mat.form-field-density(-2);
        }
      }

      .issues-container {
        border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
        padding: 0;
        border-radius: 5px;
        margin-top: 8px;

        .issue-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .issue-label {
            flex-grow: 1;
          }

          .issue-radio {
            margin-left: 8px;
          }
        }

        mat-list-item {
          padding: 0;
          padding-left: 15px;
          height: 46px;
        }
      }
    }
  }
}
