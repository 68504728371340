@use "@angular/material" as mat;

@mixin navigation-component-theme($theme) {
  .app-navigation-card {
    --width: -520px;

    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    max-width: 520px;
    width: 100%;
    height: 100%;
    /* overflow: auto; */

    .collapse-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      border: none;
      background-color: mat.get-theme-color($theme, "neutral", 100);
      outline: none;
      padding: 0;
      margin: 0;
      box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      width: 23px;
      height: 48px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      right: -23px;
      border-radius: 0 8px 8px 0;
    }

    @media (max-width: 550px) {
      --width: -100%;

      &.expanded {
        .collapse-button {
          right: 1px;
          border-radius: 8px 0 0 8px;
          z-index: 10;
        }
      }
    }

    .navigation-content-container {
      height: 100%;
      background-color: mat.get-theme-color($theme, "neutral", 100); // 98
      border-right: solid 1px mat.get-theme-color($theme, "neutral", 90);
      box-shadow:
        0 1px 2px rgba(60, 64, 67, 0.2),
        0 2px 6px 2px rgba(60, 64, 67, 0.15);
    }

    .mat-toolbar {
      background-color: mat.get-theme-color($theme, "neutral", 100);
      display: flex;
      gap: 8px;
      border-bottom: solid 1px mat.get-theme-color($theme, "neutral", 90);

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .navigation-content {
      padding: 20px;
      position: relative;

      .loading-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          mat.get-theme-color($theme, "neutral", 100),
          0.7
        );
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .location-form {
        display: grid;
        gap: 16px;

        .location-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;

          mat-form-field {
            position: relative;
            flex-shrink: 1;
            width: 100%;
            margin-bottom: -20px;

            @include mat.all-component-densities(-2);

            input {
              padding-left: 44px;
            }

            button {
              position: absolute;
              top: 50%;
              left: -12px;
              transform: translateY(-50%);
              border-radius: 4px;
              background-color: mat.get-theme-color($theme, "neutral", 95);

              ::ng-deep .mat-mdc-button-persistent-ripple {
                border-radius: 4px;
              }
            }
          }
        }
      }

      mat-divider {
        margin: 20px -20px;
        border-color: mat.get-theme-color($theme, "neutral", 90);
      }

      .route-details {
        .description {
          font-size: 18px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          text-align: center;
          margin: -20px;
          padding: 20px;
          background-color: mat.get-theme-color($theme, "primary", 99);

          mat-icon {
            color: mat.get-theme-color($theme, "primary", 60);
          }
        }
      }

      .waypoint-stepper {
        display: grid;
        padding-left: 20px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: 0;
          width: 2px;
          height: calc(100% - 35px);
          background-color: mat.get-theme-color($theme, "primary", 60);
        }

        .stepper-item {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px mat.get-theme-color($theme, "neutral", 90);

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }

          .name {
            font-size: 17px;
            font-weight: 500;
            color: mat.get-theme-color($theme, "neutral", 20);
            margin: 0;
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              left: -32px;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background-color: mat.get-theme-color($theme, "secondary", 60);

              content: "location_on";
              font-variation-settings:
                "FILL" 1,
                "wght" 400,
                "GRAD" 0,
                "opsz" 24;
              font-family: "Material Symbols Outlined";
              vertical-align: middle;
              color: mat.get-theme-color($theme, "neutral", 100);
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &.station {
            text-decoration: none;

            .name::before {
              content: "ev_station";
              background-color: mat.get-theme-color($theme, "primary", 60);
            }

            .name {
              transition: color 150ms ease;
            }

            &:hover .name {
              color: mat.get-theme-color($theme, "primary", 60);
            }
          }

          &.destination {
            .name::before {
              content: "distance";
            }
          }

          .address {
            font-size: 14px;
            font-weight: 400;
            color: mat.get-theme-color($theme, "neutral", 40);
            margin: 0;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
