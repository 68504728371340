@use '@angular/material' as mat;

@mixin filters-bottom-sheet-component-theme($theme) {
  .app-filters-bottom-sheet {
    display: grid;
    min-width: 512px;
    max-width: calc(100vw - 256px);
    padding: 16px;
    position: relative;

    @media (max-width: 520px) {
      min-width: 100%;
    }

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .filter-item {
      &:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid mat.get-theme-color($theme, 'neutral', 90);
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 16px;
        padding-bottom: 4px;
      }

      mat-list-option {
        border-radius: 100px;
      }

      .filters {
        display: grid;
      }
    }
  }
}
