@mixin search-control-theme() {
  .search-container {
    padding: 0 16px;
    background: #ffffff;
    border-radius: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #70757a !important;


    >input {
      font-size: 1rem;
      outline: none;
      border: none;
      background: inherit;
      flex-shrink: 1;
      width: 100%;
    }

    >div {
      flex-shrink: 0;
    }

    &.opened {
      background: #ffffff;
      border-radius: 16px 16px 0 0 !important;
    }
  }

}
