@use '@angular/material' as mat;

@mixin connector-item-component-theme($theme) {
  .app-connector-item {
    border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
    background-color: mat.get-theme-color($theme, 'neutral', 100);
    border-radius: 12px;
    padding: 15px 10px;

    .connector {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 40px;
        height: 40px;
      }

      p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }
    }

    .network {
      font-size: 16px;
      margin: 10px 0;
      color: mat.get-theme-color($theme, 'neutral', 40);

      strong {
        font-weight: 600;
      }
    }

    .stats {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      li {
        background-color: mat.get-theme-color($theme, 'neutral', 90);
        color: mat.get-theme-color($theme, 'neutral', 40);
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        padding: 3px 10px;
      }
    }

    .connector-action-buttons {
      margin-top: 20px;

      button {
        width: 100%;
        border-radius: 6px;
        background-color: mat.get-theme-color($theme, 'secondary', 60);

        &.wait-button {
          background-color: mat.get-theme-color($theme, 'secondary', 90);
          color: mat.get-theme-color($theme, 'secondary', 20);
        }

        &.end-button {
          background-color: mat.get-theme-color($theme, 'error', 60);
          color: mat.get-theme-color($theme, 'error', 100);
        }
      }
    }

    .activity-list {
      margin-top: 20px;
      padding-top: 10px;
      border-top: 1px solid mat.get-theme-color($theme, 'neutral', 90);

      .collapse-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        h4 {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
        }
      }

      .activities-list {
        margin-top: 20px;

        .activity-list-item:not(:last-child) {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid mat.get-theme-color($theme, 'neutral', 90);
        }
      }

      .header {
        display: flex;
        align-items: center;
        gap: 12px;

        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: mat.get-theme-color($theme, 'neutral', 90);
          color: mat.get-theme-color($theme, 'neutral', 40);
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          font-size: 20px;
        }

        .author {
          display: grid;
          gap: 1px;

          .name {
            font-size: 18px;
            font-weight: 400;
            color: mat.get-theme-color($theme, 'neutral', 20);
            margin-bottom: 0;
          }

          .vehicle {
            font-size: 16px;
            font-weight: 400;
            color: mat.get-theme-color($theme, 'neutral', 40);
          }
        }
      }

      .note {
        font-size: 16px;
        font-weight: 400;
        color: mat.get-theme-color($theme, 'neutral', 40);
        margin-top: 12px;
        margin-bottom: 0;
        white-space: pre-wrap;
      }
    }
  }
}
