@use '@angular/material' as mat;

@mixin change-password-component-theme($theme) {
  .app-change-password {
    form {
      display: grid;
      gap: 20px;
      max-width: 500px;
      width: 100%;
      margin-top: 16px;

      mat-form-field {
        margin-bottom: -20px;

        @include mat.form-field-density(-2);
      }

      button {
        margin-top: 16px;
      }
    }

    .reset-password-form {
      mat-form-field {
        margin-bottom: 0;
      }
    }
  }
}
