@use "@angular/material" as mat;

@import "./controls/search-control/search-control.theme.scss";

@mixin map-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .map-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .user-location-marker {
      position: relative;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: mat.get-theme-color($theme, "secondary", 60);
      border: 2px solid mat.get-theme-color($theme, "secondary", 100);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background-color: rgba(
          mat.get-theme-color($theme, "secondary", 60),
          0.2
        );
        border: 1px solid
          rgba(mat.get-theme-color($theme, "secondary", 60), 0.2);
        border-radius: 50%;
        z-index: -1;
        pointer-events: none;
      }
    }

    .navigation-starting-location-marker {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 8px solid mat.get-theme-color($theme, "primary", 100);
      background-color: mat.get-theme-color($theme, "primary", 60);
    }

    .navigation-ending-location-marker {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: mat.get-theme-color($theme, "primary", 60);
      border: 3px solid mat.get-theme-color($theme, "primary", 100);
      display: flex;
      align-items: center;
      justify-content: center;

      mat-icon {
        font-size: 18px;
        color: mat.get-theme-color($theme, "primary", 100);
        font-variation-settings:
          "FILL" 1,
          "wght" 400,
          "GRAD" 0,
          "opsz" 24;
      }
    }

    .navigation-waypoint-location-marker {
      background-color: mat.get-theme-color($theme, "secondary", 60);
    }

    .connector-charger-status-container {
      position: absolute;
      right: 11px;
      top: 70px;
      z-index: 999;

      .connector-status-icon {
        background-color: mat.get-theme-color($theme, "neutral", 100);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: 1px solid mat.get-theme-color($theme, "neutral", 90);
        cursor: pointer;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        outline: none;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: transparent;
          z-index: 1;
          box-sizing: border-box;
          animation: indicator-rotate 1.5s infinite;
        }

        &.indicator-green::after {
          $indicator-color: mat.get-theme-color($theme, "primary", 60);
          border: 3px solid rgba($indicator-color, 0.4);
          border-top-color: $indicator-color;
        }

        &.indicator-red::after {
          $indicator-color: mat.get-theme-color($theme, "error", 60);
          border: 3px solid rgba($indicator-color, 0.4);
          border-top-color: $indicator-color;
        }

        &.indicator-blue::after {
          $indicator-color: mat.get-theme-color($theme, "secondary", 60);
          border: 3px solid rgba($indicator-color, 0.4);
          border-top-color: $indicator-color;
        }

        @keyframes indicator-rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        svg {
          width: 18px;
        }
      }

      .connector-status-content {
        position: absolute;
        right: 65px;
        top: 0;

        visibility: hidden;
        opacity: 0;
        transform: translateX(10px);
        transition: all 0.3s ease-in-out;

        .charging-card {
          background-color: mat.get-theme-color($theme, "neutral", 100);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border: 1px solid mat.get-theme-color($theme, "neutral", 90);
          border-radius: 8px;
          padding: 0.6rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          min-width: 280px;

          .logo {
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .card-content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: center;
            cursor: pointer;

            mat-progress-bar {
              --mdc-linear-progress-active-indicator-height: 5px;
              --mdc-linear-progress-track-height: 5px;
              --mdc-linear-progress-active-indicator-color: #34D399;
              width: 188px;
              border-radius: 4px;
              background-color: mat.get-theme-color($theme, "neutral", 90);
            }

            .stats {
              display: flex;
              gap: .8rem;
              width: fit-content;

              span {
                color: mat.get-theme-color($theme, "neutral", 60);
                font-size: 12px;

                &.energy {
                  color: #34D399;
                }
              }
            }
          }
        }

        .more-details {
          margin-top: .5rem;
          background-color: mat.get-theme-color($theme, "neutral", 100);
          padding: 1rem;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border: 1px solid mat.get-theme-color($theme, "neutral", 90);

          // visibility: hidden;
          // opacity: 0;
          // transform: translateY(-10px);
          // transition: all 0.3s ease-in-out;
          display: none;

          &.show {
            // visibility: visible;
            // opacity: 1;
            // transform: translateY(0);
            display: block;
          }

          h4 {
            margin: 0;
            margin-bottom: .8rem;
            font-size: .9rem;
            font-weight: 600;
            color: mat.get-theme-color($theme, "neutral", 30);
          }

          ul {
            margin: 0;
            padding-left: .85rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            li {
              color: mat.get-theme-color($theme, "neutral", 60);
              font-size: .8rem;
              line-height: 1.2;
            }
          }
        }
      }

      &:hover .connector-status-content {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }

    .map-controls {
      position: absolute;
      right: 11px;
      bottom: 85px;
      width: 34px;
      background-color: mat.get-theme-color($theme, "neutral", 100);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 2px;
      z-index: 999;

      button {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .mat-mdc-button-persistent-ripple {
          border-radius: 4px;
        }

        mat-icon {
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.active {
          mat-icon {
            color: mat.get-theme-color($theme, "secondary", 60);
          }
        }

        &[disabled] {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }

    .leaflet-container {
      height: 100%;
      width: 100%;
      position: inherit;
      margin: 0 auto;

      .leaflet-control-container {
        position: absolute;
        right: 55px;
        bottom: 85px;

        .leaflet-control-zoom {
          border: none;
          background-color: mat.get-theme-color($theme, "neutral", 100);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          overflow: hidden;
          padding: 2px;

          a {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border: none;
          }
        }
      }
    }

    .topbar-search-bar {
      background-color: transparent;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1000;
      display: flex;
      align-items: center;
      gap: 8px;

      .toggle-sidebar {
        background-color: mat.get-theme-color($theme, "neutral", 100);
      }

      @media (max-width: 540px) {
        width: 100%;
        padding-right: 20px;

        .toggle-sidebar {
          position: absolute;
        }
      }

      .search-control-overlay {
        width: 396px;

        @include search-control-theme();

        @media (max-width: 540px) {
          width: 100%;

          .search-container {
            padding-left: 46px;
          }
        }

        & > * {
          width: 100%;
        }
      }
    }

    .topbar-user-dropdown {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1000;
    }

    .arrow-icon {
      z-index: 0 !important;
      pointer-events: none;
    }

    .leaflet-control-container {
      .leaflet-top.leaflet-right {
        display: flex;
      }
    }

    svg.leaflet-tile {
      overflow: visible;
    }
  }

  .leaflet-container {
    font-family: inherit;
  }

  .leaflet-event-marker {
    position: relative;
    border: none;
    background-color: transparent;

    .event-marker-container {
      display: flex;
      align-items: center;

      .text {
        position: absolute;
        left: calc(100% + 10px);
        top: 0;
        line-height: 1.2;
        font-weight: 500;
      }
    }
  }

  .leaflet-event-popup {
    @media (max-width: 400px) {
      width: 310px;
    }
  }

  .station-pin-overview, .address-pin-overview {
    @media (max-width: 420px) {
      .leaflet-popup-content {
        width: calc(100vw - 45px) !important;
      }

      .station-preview-container, .address-preview-container {
        width: 100% !important;
      }
    }
  }
}
