@use '@angular/material' as mat;
@import './connector-item/connector-item.theme';

@mixin connectors-tab-component-theme($theme) {
  .app-connectors-tab {
    .filters {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      gap: 16px;

      mat-form-field {
        width: 100%;
        font-size: 14px;
        margin-bottom: -20px;

        @include mat.all-component-densities(-4);
      }

      .filter-button {
        flex-shrink: 0;
        background-color: mat.get-theme-color($theme, 'secondary', 90);
        color: mat.get-theme-color($theme, 'secondary', 0);
      }
    }

    .chargers {
      display: grid;
      gap: 30px;
    }

    .charger {
      border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
      background-color: mat.get-theme-color($theme, 'neutral', 100);
      border-radius: 12px;
      box-shadow: none;
      overflow: hidden;

      mat-card-header {
        border-bottom: 1px solid mat.get-theme-color($theme, 'neutral', 90);
        background-color: #eee;
        padding-bottom: 12px;
        position: relative;

        button {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      mat-card-content {
        padding: 16px;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
        text-align: start;
      }

      .connectors {
        display: grid;
        gap: 12px;

        @include connector-item-component-theme($theme);
      }
    }
  }
}
