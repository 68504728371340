@use '@angular/material' as mat;

@mixin address-preview-component-theme($theme) {
  .address-pin-overview {
    .leaflet-popup-content {
      margin: 16px !important;
    }
  }

  .address-preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 370px;

    .address-preview-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      position: relative;
      padding-right: 62px;
      min-height: 40px;

      button {
        flex-shrink: 0;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;

        .mat-mdc-button-persistent-ripple {
          border-radius: inherit;
        }
      }
    }

    .address-name {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .full-address {
      font-size: 16px;
      font-weight: 400;
      color: mat.get-theme-color($theme, "neutral", 40);
      margin: 0;
    }
  }
}
