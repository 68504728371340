@use '@angular/material' as mat;

@mixin connector-list-component-theme($theme) {
  .app-connector-list {
    .connectors {
      display: flex;
      gap: 12px;
      /* overflow-x: auto; */

      .connector-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        height: 60px;
        flex: 1;
        min-width: 200px;
        border-radius: 10px;
        border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
        background-color: mat.get-theme-color($theme, 'neutral', 100);

        img {
          width: 40px;
          height: 40px;
        }

        .content {
          h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 2px;
          }

          p {
            margin: 0;
            font-size: 14px;
            color: mat.get-theme-color($theme, 'neutral', 40);
          }
        }
      }
    }
  }
}
