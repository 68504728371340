@use '@angular/material' as mat;

@mixin account-component-theme($theme) {
  .app-account-layout {
    display: flex;
    gap: 24px;
    min-height: 100%;

    .account-nav {
      background-color: mat.get-theme-color($theme, "neutral", 100);
      width: 280px;
      padding: 16px;
      border-radius: 12px;
      text-align: center;
      height: 100%;
      display: grid;
      gap: 8px;

      @media (max-width: 768px) {
        padding: 0;
        margin-left: -16px;
        width: calc(100% + 16px);
      }

      a {
        font-size: 16px;
        text-decoration: none;
        transition: 150ms ease-in-out;
        text-align: start;
        border-radius: 4px;
        justify-content: flex-start;
        color: mat.get-theme-color($theme, "neutral", 40);
        padding: 8px 16px;
        height: 40px;

        &.activated {
          background-color: mat.get-theme-color($theme, 'secondary', 90);
          color: mat.get-theme-color($theme, 'neutral', 20) !important;

          mat-icon,
          span {
            color: mat.get-theme-color($theme, 'neutral', 20) !important;
          }
        }
      }
    }

    .account-content {
      flex-shrink: 1;
      width: calc(100% - 280px);
      min-height: 100%;

      .account-page-title {
        font-size: 20px;
        font-weight: 600;
        color: mat.get-theme-color($theme, "neutral", 20);
        margin-bottom: 28px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .account-content {
        width: 100%;
      }
    }
  }

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 28px;

    .account-page-title {
      margin-bottom: 0 !important;
    }

    button {
      background-color: mat.get-theme-color($theme, 'secondary', 60);

      mat-icon {
        color: mat.get-theme-color($theme, 'secondary', 100);
      }
    }
  }

}
