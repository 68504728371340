@use '@angular/material' as mat;

@mixin contact-component-theme($theme) {
  .app-contact {
    padding: 115px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .header {
      text-align: center;
      max-width: 706px;
      width: 100%;
      margin: 0 auto;

      h1 {
        font-size: 48px;
        margin-bottom: 48px;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: 1.6;
        color: mat.get-theme-color($theme, 'neutral', 40);

        a {
          color: currentColor;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }

    .ui-elements {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;

      svg {
        position: absolute;
      }

      .left {
        top: 30%;
        left: 0;
      }

      .middle {
        top: 40%;
        left: 30%;
      }

      .right {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
}
