@use '@angular/material' as mat;

@mixin amenities-list-component-theme($theme) {
  .app-amenities-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
    margin: 0;
    column-gap: 12px;
    row-gap: 30px;

    button {
      display: grid;
      justify-content: center;
      align-items: center;
      gap: 8px;
      text-align: center;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      transition: 150ms ease-in-out;

      &.selectable {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
        background-color: mat.get-theme-color($theme, 'secondary', 100);
        transition: 150ms ease-in-out;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }

      &.selected .icon {
        background-color: mat.get-theme-color($theme, 'secondary', 95);
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        font-size: 14px;
        color: mat.get-theme-color($theme, 'neutral', 40);
      }
    }
  }
}
