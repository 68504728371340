@use '@angular/material' as mat;

@mixin sidebar-component-theme($theme) {
  mat-sidenav-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
      &:has(mat-sidenav.opened) {
        &::before {
          content: "";
          position: fixed;
          inset: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 2;
        }
      }
    }

    mat-icon {
      color: mat.get-theme-color($theme, 'neutral', 40);
    }

    .icon-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .login-buttons {
      display: flex;
      gap: 12px;

      button,
      a {
        height: 48px;
        border-radius: 5px;
        padding: 12px 26px;
        font-size: 16px;
      }
    }

    [mat-list-item] {

      mat-icon,
      span {
        color: mat.get-theme-color($theme, 'neutral', 40);
      }

      &.activated {
        background-color: mat.get-theme-color($theme, 'secondary', 60);
        color: white;

        mat-icon,
        span {
          color: white;
        }
      }
    }

    mat-sidenav {
      max-width: 240px;
      min-width: 80px;
      border-radius: 0;
      border-right: solid 1px mat.get-theme-color($theme, 'neutral', 90);
      background-color: white;

      @media (max-width: 768px) {
        max-width: 320px;
        width: 100% !important;
        position: fixed;
        inset: 0;
        height: 100%;

        display: none;

        &.opened {
          display: block;
        }
      }

      mat-divider {
        border-color: mat.get-theme-color($theme, 'neutral', 90);
      }

      .sidenav {
        padding: 20px 20px 32px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .logo-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;

          .logo {
            height: 45px;
            width: 100%;
            object-fit: contain;
            object-position: left;
          }
        }

        .nav-links {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 100%;

          mat-nav-list {
            gap: 8px;
            display: grid;
            padding: 4px 0;

            a, button {
              border-radius: 4px;
              height: 40px;
              padding: 8px;
              display: flex;
              align-items: center;
              gap: 8px;

              mat-icon {
                margin: 0;
              }
            }

            .nested-list {
              padding-left: 20px;
            }
          }
        }

        .download-links {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;
          padding: 8px 0;

          a {
            display: block;

            img {
              height: 30px;

              @media (max-width: 768px) {
                height: 43px;
              }
            }
          }

          &.one-column {
            grid-template-columns: 1fr;

            a {
              width: 20px;
              margin: 0 auto;

              img {
                height: 24px;
              }
            }
          }
        }
      }
    }

    mat-sidenav-content {
      height: 100%;
      width: 100%;
      overflow: auto;

      @media (max-width: 768px) {
        padding-left: 0 !important;
      }
    }
  }
}
