// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
//@use './m3-theme.scss';
// Plus imports for other components in your app.
@use '@angular/material' as mat;
@use './m3-theme.scss' as m3-theme;

@include mat.core();
//@include mat.all-component-themes(m3-theme.$light-theme);


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

/* You can add global styles to this file, and also import other style files */

* { box-sizing: border-box; }
html {
  //@include mat.all-component-themes(m3-theme.$light-theme);
  // @include mat.all-component-bases(m3-theme.$light-theme);
  // @include mat.all-component-colors(m3-theme.$light-theme);


  //@include mat.system-level-colors(m3-theme.$light-theme);
  //@include mat.system-level-typography(m3-theme.$light-theme);

  // @include mat.core-theme(m3-theme.$light-theme);
  // @include mat.button-theme(m3-theme.$light-theme);

}
html, body { height: 100%; }
body { margin: 0; font-family: "Noto Sans", "Roboto", "Helvetica Neue", sans-serif; }

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 4px;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.dash-container {
  padding: 24px 32px;
}

.hide-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.show-mobile {
  @media (min-width: 769px) {
    display: none !important;
  }
}

