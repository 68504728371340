@use '@angular/material' as mat;

@mixin profile-component-theme($theme) {
  .app-profile-layout {
    display: flex;
    gap: 24px;
    min-height: 100%;

    .user-card {
      background-color: mat.get-theme-color($theme, "neutral", 100);
      width: 280px;
      padding: 24px 16px;
      border-radius: 12px;
      text-align: center;
      height: 100%;

      .user-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        background-color: mat.get-theme-color($theme, "neutral", 90);
        margin: 0 auto;
        position: relative;

        mat-icon {
          font-size: 48px;
          width: 48px;
          height: 48px;
          color: mat.get-theme-color($theme, "neutral", 20);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          inset: 0;
        }
      }

      .user-name {
        font-size: 18px;
        font-weight: 600;
        margin-top: 16px;
        margin-bottom: 0;
        color: mat.get-theme-color($theme, "neutral", 20);
      }

      p {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        color: mat.get-theme-color($theme, "neutral", 60);
      }

      .user-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;

        button {
          color: currentColor;

          mat-icon {
            color: mat.get-theme-color($theme, "secondary", 60);
          }

          @include mat.button-color($theme, $color-variant: tertiary);
        }
      }
    }

    .profile-content {
      flex-shrink: 1;
      width: calc(100% - 280px);
      min-height: 100%;

      .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        margin-bottom: 24px;

        .nav-arrow {
          display: none;

          @media (max-width: 655px) {
            display: block;

            &.left-arrow {
              margin-left: -30px;
            }
            &.right-arrow {
              margin-right: -30px;
            }
          }
        }
      }

      .profile-nav {
        display: flex;
        align-items: center;
        gap: 40px;
        overflow: auto;

        // Hide scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        a {
          flex: 0 0 auto;
          font-size: 16px;
          font-weight: 400;
          color: mat.get-theme-color($theme, "neutral", 40);
          text-decoration: none;
          transition: color 0.2s ease-in-out;

          &:hover {
            opacity: 0.8;
          }

          &.active {
            color: mat.get-theme-color($theme, "primary", 60);
            font-weight: 500;
          }
        }
      }

      .profile-page-content:has(.no-stations) {
        min-height: calc(100% - 22px);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .profile-page-title {
        font-size: 20px;
        font-weight: 600;
        color: mat.get-theme-color($theme, "neutral", 20);
        margin-bottom: 28px;
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;

      .user-card, .profile-content {
        width: 100%;
      }
    }
  }
}
