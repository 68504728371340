@use '@angular/material' as mat;

@mixin station-info-component-theme($theme) {
  .app-station-info {
    .map {
      width: 100%;
      height: 200px;
      border-radius: 4px;
    }

    mat-divider {
      margin: 25px 0;
      border-color: mat.get-theme-color($theme, 'neutral', 90);
    }

    .section {
      .section-title {
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 12px;
      }
    }

    .general-details {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: grid;
        gap: 12px;

        li {
          font-size: 16px;
          color: mat.get-theme-color($theme, 'neutral', 40);

          strong {
            font-weight: 600;
          }

          .expand-button {
            background-color: mat.get-theme-color($theme, 'secondary', 95);
            border-radius: 50%;
            vertical-align: middle;
            margin-left: 8px;
            font-size: 22px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .hours {
        margin: 12px 0;
        display: grid;
        gap: 16px;

        &>div:has(.hide-parent) {
          display: none;
        }

        ul {
          margin-top: 4px;
          gap: 6px;

          li {
            display: flex;
            gap: 12px;
          }
        }

        .open-24 {
          color: mat.get-theme-color($theme, 'primary', 50);
        }
      }
    }

    .toggle-button {
      text-decoration: underline;
      color: mat.get-theme-color($theme, 'secondary', 50);
      cursor: pointer;
      padding: 0;
      outline: none;
      border: none;
      background: none;
      font-weight: 500;
      font-size: 14px;
      margin-top: 20px;
    }

    .station-description {
      white-space: pre-wrap;
    }

    .station-description,
    .last-modified {
      color: mat.get-theme-color($theme, 'neutral', 40);
      font-size: 16px;
    }
  }
}
