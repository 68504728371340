@use "@angular/material" as mat;

@mixin emergency-service-component-theme($theme) {
  .app-emergency-service {
    .places {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;

      .station-preview-container {
        width: 100%;
        background-color: mat.get-theme-color($theme, "neutral", 100);
        display: flex;
        flex-direction: column;
        box-shadow: none;
        border: 1px solid mat.get-theme-color($theme, "neutral", 90);
        height: 100%;

        .station-preview-header {
          display: block;

          .station-name {
            margin-bottom: 0;
          }

          .location-type {
            margin-top: 5px;
            margin-bottom: 0;
          }
        }

        .station-rating {
          margin-top: 5px;
          margin-bottom: 0;
        }

        .info-list {
          margin: 12px 0;
        }

        mat-card-footer {
          margin-top: auto;
        }

        .buttons {
          display: flex;
          justify-content: center;
          gap: 4px;
          flex-wrap: wrap;
          margin-top: auto;
          border-top: 1px solid mat.get-theme-color($theme, "neutral", 90);

          [mat-icon-button] mat-icon {
            color: mat.get-theme-color($theme, "secondary", 60);
          }
        }
      }
    }

    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      gap: 16px;
      text-align: center;
      color: mat.get-theme-color($theme, "neutral", 20);
      font-size: 16px;

      img {
        max-width: 400px;
        width: 100%;
        height: auto;
      }
    }
  }
}
