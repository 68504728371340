// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@import 'app/pages/map/map.theme';
@import 'app/pages/station-details/station-details.theme';
@import 'app/pages/navigation/navigation.theme';
@import 'app/pages/station-image/station-image.theme';
@import 'app/pages/contact/contact.theme';
@import 'app/pages/profile/added-stations/added-stations.theme';
@import 'app/pages/profile/rewards/rewards.theme';
@import 'app/pages/account/personal-information/personal-information.theme';
@import 'app/pages/account/change-password/change-password.theme';
@import 'app/pages/account/vehicles/vehicles.theme';
@import 'app/pages/account/wallet/wallet.theme';
@import 'app/pages/help/emergency-service/emergency-service.theme';

@import 'app/components/station-details/connectors-tab/filters-bottom-sheet/filters-bottom-sheet.theme';
@import 'app/components/user-dropdown/user-dropdown.theme';
@import 'app/components/bookmark-dialog/bookmark-dialog.theme';
@import 'app/components/profile/stations-list/stations-list.theme';
@import 'app/components/profile/station-list-item/station-list-item.theme';
@import 'app/components/station-details/review-item/review-item.theme';
@import 'app/components/account/vehicles/add-vehicle-dialog/add-vehicle-dialog.theme';
@import 'app/components/report-dialog/report-dialog.theme';
@import 'app/components/profile/reward-dialog/reward-dialog.theme';
@import 'app/components/station-preview/station-preview.theme.scss';
@import 'app/components/address-preview/address-preview.theme.scss';
@import 'app/components/station-details/connector-list/connector-list.theme';
@import 'app/components/wallet/add-wallet-dialog/add-wallet-dialog.theme';

@import 'app/layout/sidebar/sidebar.theme';
@import 'app/layout/legal/legal.theme';
@import 'app/layout/profile/profile.theme';
@import 'app/layout/topbar/topbar.theme';
@import 'app/layout/account/account.theme';
@import 'app/layout/help/help.theme';

// Note: Color palettes are generated from primary: #05BA00, secondary: #29AAFF
$_palettes: (
  primary: (
    0: #000000,
    10: #002200,
    20: #013a00,
    25: #014600,
    30: #025300,
    35: #026100,
    40: #036e00,
    50: #048b00,
    60: #05a800,
    70: #23c617,
    80: #4ae338,
    90: #77ff60,
    95: #cbffb8,
    98: #edffe1,
    99: #f7ffee,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #001d32,
    20: #003353,
    25: #003e64,
    30: #004a75,
    35: #005688,
    40: #00639a,
    50: #007dc1,
    60: #0097e9,
    70: #4fb2ff,
    80: #96ccff,
    90: #cee5ff,
    95: #e8f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #002022,
    20: #003739,
    25: #0f4245,
    30: #1e4d50,
    35: #2b595c,
    40: #386568,
    50: #517f81,
    60: #6b989b,
    70: #85b3b6,
    80: #a0cfd2,
    90: #bcebee,
    95: #caf9fc,
    98: #e7feff,
    99: #f2ffff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1a1c18,
    20: #2f312d,
    25: #3a3c38,
    30: #454743,
    35: #51534e,
    40: #5d5f5a,
    50: #767872,
    60: #90918c,
    70: #abaca6,
    80: #c6c7c1,
    90: #e2e3dc,
    95: #f1f1eb,
    98: #f9faf3,
    99: #fcfdf6,
    100: #ffffff,
    4: #0d0f0b,
    6: #121410,
    12: #1e201c,
    17: #282b27,
    22: #333531,
    24: #383a35,
    87: #dadad4,
    92: #e8e9e2,
    94: #eeeee8,
    96: #f4f4ed,
  ),
  neutral-variant: (
    0: #000000,
    10: #181d15,
    20: #2c3229,
    25: #373d34,
    30: #43483f,
    35: #4e544a,
    40: #5a6056,
    50: #73796e,
    60: #8d9387,
    70: #a7ada1,
    80: #c3c8bc,
    90: #dfe4d7,
    95: #edf3e5,
    98: #f6fbee,
    99: #f9fef1,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    plain-family: ("Noto Sans", "Roboto", "Helvetica Neue", sans-serif),
    brand-family: ("Noto Sans", "Roboto", "Helvetica Neue", sans-serif),
    bold-weight: ("Noto Sans", "Roboto", "Helvetica Neue", sans-serif),
    medium-weight: ("Noto Sans", "Roboto", "Helvetica Neue", sans-serif),
    regular-weight: ("Noto Sans", "Roboto", "Helvetica Neue", sans-serif),
    use-system-variables: true,
  ),
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

@mixin apply-gc-theme($theme) {
  mat.$theme-ignore-duplication-warnings: true;

  @include mat.all-component-themes($theme);
  @include mat.system-level-colors($theme);
  @include mat.system-level-typography($theme);

  @include map-component-theme($theme);
  @include station-details-component-theme($theme);
  @include navigation-component-theme($theme);
  @include station-image-component-theme($theme);
  @include contact-component-theme($theme);
  @include personal-information-component-theme($theme);
  @include change-password-component-theme($theme);
  @include vehicles-component-theme($theme);
  @include wallet-component-theme($theme);
  @include rewards-component-theme($theme);
  @include emergency-service-component-theme($theme);

  @include filters-bottom-sheet-component-theme($theme);
  @include user-dropdown-component-theme($theme);
  @include bookmarks-dialog-component-theme($theme);
  @include add-vehicle-dialog-component-theme($theme);

  @include sidebar-component-theme($theme);
  @include legal-component-theme($theme);
  @include profile-component-theme($theme);
  @include topbar-component-theme($theme);
  @include account-component-theme($theme);
  @include help-component-theme($theme);

  @include added-stations-component-theme($theme);
  @include stations-list-component-theme($theme);
  @include station-list-item-theme($theme);
  @include review-item-component-theme($theme);
  @include report-dialog-component-theme($theme);
  @include reward-dialog-component-theme($theme);
  @include station-preview-component-theme($theme);
  @include address-preview-component-theme($theme);
  @include connector-list-component-theme($theme);
  @include add-wallet-dialog-component-theme($theme);

  mat-divider {
    border-color: mat.get-theme-color($theme, 'neutral', 90);
  }

  .text-green {
    color: mat.get-theme-color($theme, 'primary', 50);
  }

  .text-blue {
    color: mat.get-theme-color($theme, 'secondary', 50);
  }

  .text-red {
    color: #ef4444;
  }

  .text-orange {
    color: rgb(252, 211, 77);
  }

  mat-bottom-sheet-container {
    background-color: mat.get-theme-color($theme, 'neutral', 100);
  }
}

.gc-default-theme {
  @include apply-gc-theme($light-theme);
  --sys-background: #ffffff;
}

.gc-dark-theme {
  @include apply-gc-theme($dark-theme);
}
