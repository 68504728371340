@use "@angular/material" as mat;

@mixin reward-dialog-component-theme($theme) {
  .app-reward-dialog {
    background-color: mat.get-theme-color($theme, "neutral", 100);
    height: 100%;
    position: relative;
    overflow: auto;
    max-height: 90vh;

    .header {
      text-align: center;
      position: relative;
      padding: 18px 32px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
    }

    .close-button {
      position: absolute;
      top: 18px;
      right: 18px;
      z-index: 10;
    }

    .content {
      padding: 18px 32px;
      text-align: center;
      display: grid;
      gap: 16px;

      p {
        margin: 0;
      }

      a {
        color: mat.get-theme-color($theme, "primary", 50);
      }
    }

    .station-content {
      padding: 26px 20px;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }

      &.qr-code-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      .station-title {
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }

      .station-rating {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: mat.get-theme-color($theme, "neutral", 10);
          font-size: 14px;
          display: inline-block;
        }

        .icons {
          display: flex;
          align-items: center;

          mat-icon {
            width: auto;
            height: auto;
            font-size: 20px;
            color: mat.get-theme-color($theme, "neutral", 80);
            font-variation-settings:
              "FILL" 1,
              "wght" 400,
              "GRAD" 0,
              "opsz" 24;

            &.active {
              color: #fcd34d;
            }
          }
        }
      }

      .station-contacts {
        display: grid;
        gap: 14px;
        padding-left: 0;
        margin: 20px 0;

        .station-contact-item {
          display: flex;
          align-items: center;
          gap: 12px;

          mat-icon {
            color: mat.get-theme-color($theme, "neutral", 20);
            background-color: mat.get-theme-color($theme, "neutral", 100);
            border: 1px solid mat.get-theme-color($theme, "neutral", 90);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
          }

          img {
            width: 26px;
            height: auto;
          }

          a,
          span {
            color: mat.get-theme-color($theme, "neutral", 20);
            font-size: 16px;
          }
        }
      }

      .action-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        a,
        button {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &.active {
            font-variation-settings:
              "FILL" 1,
              "wght" 400,
              "GRAD" 0,
              "opsz" 24;

            mat-icon {
              color: mat.get-theme-color($theme, "secondary", 60);
            }
          }
        }

        mat-divider {
          height: 18px;
          border-color: mat.get-theme-color($theme, "neutral", 80);
          margin: 0 12px;
        }
      }

      .tab-content {
        margin-top: 20px;
      }

      .divider {
        margin: 0 -20px;
        margin-bottom: 20px;
      }

      .section {
        .section-title {
          font-size: 18px;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 12px;
        }
      }
    }

    .toggle-button {
      text-decoration: underline;
      color: mat.get-theme-color($theme, "secondary", 50);
      cursor: pointer;
      padding: 0;
      outline: none;
      border: none;
      background: none;
      font-weight: 500;
      font-size: 14px;
      margin-top: 6px;
    }
  }
}
