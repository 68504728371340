@use '@angular/material' as mat;

@mixin stations-list-component-theme($theme) {
  .app-stations-list {
    display: grid;
    align-items: flex-start;
    gap: 4px;

    .no-stations {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      gap: 16px;
      text-align: center;
      color: mat.get-theme-color($theme, "neutral", 20);
      font-size: 16px;

      img {
        max-width: 400px;
        width: 100%;
        height: auto;
      }
    }

    .stations-loading {
      display: flex;
      align-items: center;
      gap: 8px;
      color: mat.get-theme-color($theme, "neutral", 20);
      font-size: 16px;
    }
  }
}
