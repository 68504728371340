@use "@angular/material" as mat;

@mixin wallet-component-theme($theme) {
  .app-wallet {
    .wallet-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
      gap: 20px;

      @media (max-width: 894px) {
        grid-template-columns: 1fr;
      }

      @media (min-width: 359px) and (max-width: 519px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: .7rem;
      }
    }

    .credit-card {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;
      border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
      padding: .5rem 1rem;
      border-radius: 10px;

      .credit-card-content {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1rem;

        .logo {
          height: max-content;
          display: flex;

          img {
            width: 75px;
            height: auto;
          }
        }

        .content {
          .number {
            font-size: 1rem;
            margin-bottom: 0.2rem;
          }

          .details {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 14px;
            gap: 0.8rem;
            font-weight: medium;
            color: mat.get-theme-color($theme, 'neutral', 30);

            .card-holder {
              .label {
                font-size: 10px;
                color: mat.get-theme-color($theme, 'neutral', 50);
              }
            }

            .expiry {
              .label {
                font-size: 10px;
                color: mat.get-theme-color($theme, 'neutral', 50);
              }
            }
          }
        }
      }

      .credit-card-actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: .7rem;

        .default {
          background-color: mat.get-theme-color($theme, 'neutral', 10);
          color: mat.get-theme-color($theme, 'neutral', 90);
          border-radius: 5px;
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
          font-weight: 500;
          display: inline-block;
          width: fit-content;
        }

        .set-as-default {
          color: mat.get-theme-color($theme, 'secondary', 50);
          font-size: 0.8rem;
          font-weight: 500;
          border: none;
          background-color: transparent;
          outline: none;
          padding: 0;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          gap: .2rem;
        }

        .delete-button {
          color: mat.get-theme-color($theme, 'neutral', 60);
          font-size: 0.8rem;
          font-weight: 500;
          border: none;
          background-color: transparent;
          outline: none;
          padding: 0;
          cursor: pointer;
          display: flex;
          transition: color 0.2s ease-in-out;

          mat-icon {
            color: currentColor;
            font-size: 24px;
            width: 24px;
            height: 24px;
          }

          &:hover {
            color: mat.get-theme-color($theme, 'error', 50);
          }
        }
      }

      @media (max-width: 519px) {
        flex-direction: column;
        align-items: center;

        .credit-card-content {
          flex-direction: column;
          align-items: center;
          text-align: center;

          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;

            .number {
              font-size: .9rem;
              margin-bottom: .7rem;
            }

            .details {
              margin-top: auto;
              flex-direction: column;
              align-items: center;
              gap: .2rem;
              font-size: .8rem;
            }
          }
        }
      }
    }
  }
}
