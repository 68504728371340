@use '@angular/material' as mat;

@mixin vehicles-component-theme($theme) {
  .app-vehicles {
    position: relative;

    .add-vehicle-button {
      position: fixed;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      @media (min-width: 768px) {
        margin-left: 190px;
      }

      font-weight: 500;
      background-color: mat.get-theme-color($light-theme, 'secondary', 95);
      color: mat.get-theme-color($light-theme, 'secondary', 60);
      border-color: currentColor;

      @include mat.button-color($light-theme, $color-variant: secondary);

      mat-icon {
        color: currentColor;
      }
    }

    .app-vehicle-item {
      display: flex;
      align-items: center;
      gap: 12px;
      border-bottom: 1px solid mat.get-theme-color($theme, 'neutral', 90);
      margin-bottom: 20px;
      padding-bottom: 20px;

      .vehicle-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 54px;
        height: 54px;
        border-radius: 12px;
        background-color: mat.get-theme-color($theme, 'neutral', 95);
        overflow: hidden;
        flex-shrink: 0;

        .vehicle-image,
        mat-icon {
          width: 100%;
          height: 100%;
          font-size: 40px;
          position: absolute;
          inset: 0;
          background-color: mat.get-theme-color($theme, 'neutral', 95);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .vehicle-details {
        display: grid;
        gap: 2px;
        flex-shrink: 1;

        .vehicle-make {
          font-size: 16px;
          font-weight: 500;
          color: mat.get-theme-color($theme, 'neutral', 20);
        }

        .vehicle-model,
        .vehicle-trim {
          font-size: 14px;
          font-weight: 400;
          color: mat.get-theme-color($theme, 'neutral', 50);
        }

        p {
          margin: 0;
        }
      }

      .vehicle-radio {
        flex-shrink: 0;
      }

      .action-buttons {
        margin-left: auto;
        flex-shrink: 0;
      }
    }
  }
}
