@use '@angular/material' as mat;

@mixin bookmarks-dialog-component-theme($theme) {
  .app-bookmarks-dialog {
    background-color: mat.get-theme-color($theme, 'neutral', 100);
    height: 100%;

    .header {
      text-align: center;
      position: relative;
      padding: 18px 32px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      .close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
      }
    }

    .content {
      padding: 10px 32px;
      display: grid;
      height: calc(100% - 61.5px);

      .no-bookmarks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        margin-top: -61.5px;

        i {
          color: mat.get-theme-color($theme, 'neutral', 40);
        }

        p {
          margin: 0;
        }
      }

      .stations-list {
        .station-item {
          display: block;
          color: mat.get-theme-color($theme, 'neutral', 20);
          text-decoration: none;
          padding: 15px;
          margin: 0 -15px;
          border-radius: 8px;

          display: flex;
          align-items: center;
          gap: 12px;

          .image {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: mat.get-theme-color($theme, 'neutral', 90);
            color: mat.get-theme-color($theme, 'neutral', 40);
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-size: 20px;

            app-gc-image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              position: absolute;
              inset: 0;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
              }
            }
          }

          .details {
            display: grid;
            gap: 1px;

            .name {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: mat.get-theme-color($theme, 'neutral', 20);
            }

            .address {
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              color: mat.get-theme-color($theme, 'neutral', 40);
            }
          }

          .delete-button {
            flex-shrink: 1;
            margin-left: auto;

            @media (min-width: 769px) {
              opacity: 0;
            }
          }

          &:hover {
            background-color: mat.get-theme-color($theme, 'neutral', 95);

            .delete-button {
              opacity: 1;
            }
          }
        }

        > mat-divider {
          margin: 3px 0;
        }
      }
    }
  }
}
