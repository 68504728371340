@use '@angular/material' as mat;

@import '../../components/station-details/amenities-list/amenities-list.theme';
@import '../../components/station-details/ratings-tab/ratings-tab.theme';
@import '../../components/station-details/station-info/station-info.theme';
@import '../../components/station-details/connectors-tab/connectors-tab.theme';
@import '../../components/station-details/connectors-tab/filters-bottom-sheet/filters-bottom-sheet.theme';

@mixin station-details-component-theme($theme) {
  .app-station-details {
    background-color: mat.get-theme-color($theme, 'neutral', 100); // 98
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    max-width: 520px;
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
      max-width: 100%;
      box-shadow: none;
    }

    .mat-toolbar {
      background-color: mat.get-theme-color($theme, 'neutral', 100);
      height: max-content;

      @media (min-width: 601px) {
        display: none;
      }
    }

    .station-image {
      position: relative;
      width: 100%;
      aspect-ratio: 16 / 9;

      mat-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px !important;
        height: 40px !important;
      }

      .image-buttons {
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        padding: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        button {
          border-radius: 4px;
        }
      }
    }

    .station-content {
      padding: 26px 20px;

      .station-title {
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }

      .station-rating {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: mat.get-theme-color($theme, 'neutral', 10);
          font-size: 14px;
          display: inline-block;
        }

        .icons {
          display: flex;
          align-items: center;

          mat-icon {
            width: auto;
            height: auto;
            font-size: 20px;
            color: mat.get-theme-color($theme, 'neutral', 80);
            font-variation-settings:
              'FILL' 1,
              'wght' 400,
              'GRAD' 0,
              'opsz' 24;

            &.active {
              color: #fcd34d;
            }
          }
        }
      }

      .station-contacts {
        display: grid;
        gap: 14px;
        padding-left: 0;
        margin: 20px 0;

        .station-contact-item {
          display: flex;
          align-items: center;
          gap: 12px;

          mat-icon {
            color: mat.get-theme-color($theme, 'neutral', 20);
            background-color: mat.get-theme-color($theme, 'neutral', 100);
            border: 1px solid mat.get-theme-color($theme, 'neutral', 90);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
          }

          img {
            width: 26px;
            height: auto;
          }

          a,
          span {
            color: mat.get-theme-color($theme, 'neutral', 20);
            font-size: 16px;
          }
        }
      }

      .action-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        a, button {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &.active {
            font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24;

            mat-icon {
              color: mat.get-theme-color($theme, 'secondary', 60);
            }
          }
        }

        mat-divider {
          height: 18px;
          border-color: mat.get-theme-color($theme, 'neutral', 80);
          margin: 0 12px;
        }
      }

      .tab-content {
        margin-top: 20px;
      }
    }

    @include amenities-list-component-theme($theme);
    @include ratings-tab-component-theme($theme);
    @include station-info-component-theme($theme);
    @include connectors-tab-component-theme($theme);
    @include filters-bottom-sheet-component-theme($theme);
  }
}
