@use '@angular/material' as mat;

@mixin station-list-item-theme($theme) {
  .app-station-list-item {
    display: block;
    color: mat.get-theme-color($theme, 'neutral', 20);
    text-decoration: none;
    padding: 15px;
    margin: 0 -15px;
    border-radius: 8px;

    &:hover {
      background-color: mat.get-theme-color($theme, 'neutral', 95);
    }

    display: flex;
    align-items: flex-start;
    gap: 12px;

    .image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: mat.get-theme-color($theme, 'neutral', 90);
      color: mat.get-theme-color($theme, 'neutral', 40);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 20px;

      app-gc-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        inset: 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .details {
      display: grid;
      gap: 1px;
      width: 100%;

      .name {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: mat.get-theme-color($theme, 'neutral', 20);
      }

      .address {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: mat.get-theme-color($theme, 'neutral', 40);
      }

      .created-at {
        margin: 0;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 400;
        color: mat.get-theme-color($theme, 'neutral', 50);
      }
    }

    .station-images {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 8px;
      margin-top: 12px;

      @media (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      a {
        position: relative;
        width: 100%;
        height: 210px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
