@use '@angular/material' as mat;

@mixin rewards-component-theme($theme) {
  .app-rewards {
    .rewards-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      gap: 16px;

      .reward-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: mat.get-theme-color($theme, "neutral", 100);
        border-radius: 12px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        font-weight: 500;

        .icon {
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &.desaturated .icon img {
          mix-blend-mode: luminosity;
        }

        .reward-name {
          font-size: 16px;
          font-weight: 400;
          margin-top: 12px;
          margin-bottom: 8px;
          color: mat.get-theme-color($theme, "neutral", 20);
        }

        .date {
          margin: 0;
          font-size: 12px;
          color: mat.get-theme-color($theme, "neutral", 60);
        }
      }
    }
  }
}
